import {
  PARTNERS_LOGIN_URL,
  PARTNERS_SIGNUP_URL,
} from '@/components/pages/partners/blog/constants';
import type {Handle, I18NOptions} from '@/types';

export const partnersHandle: Handle = {
  globalNav({t}: I18NOptions) {
    return {
      loginUrl: PARTNERS_LOGIN_URL,
      loginText: t('global:nav.login'),
      signupText: t('global:nav.joinNow'),
    };
  },
  signupUrl: PARTNERS_SIGNUP_URL,
};
